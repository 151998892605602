import { Dropdown, Menu, Popover, List, Empty, message } from "antd";
import { useLocation, useHistory, Switch, Route } from "react-router-dom";
import React, { useGlobal, useEffect, setGlobal, useState } from "reactn";
import call from "../call";
import Card from "./card";
import {
  UserOutlined,
  SettingFilled,
  LogoutOutlined,
  MoreOutlined,
  LeftOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Button from "./button";
import Text from "./text";
import FlexSearch from "flexsearch";
import { ReactComponent as OverviewIcon } from "./icons/Overview.svg";
import { ReactComponent as GamesIcon } from "./icons/Games.svg";
import { ReactComponent as DownloadsIcon } from "./icons/Downloads.svg";
import { ReactComponent as InsightsIcon } from "./icons/Insights.svg";
import { ReactComponent as FAQIcon } from "./icons/FAQ.svg";
import { ReactComponent as AlertIcon } from "./icons/alert.svg";
import SearchBox from "./SearchBox";

let ProfileMenu = () => {
  let history = useHistory();
  let [user] = useGlobal("user");
  let [open, setOpen] = useState(false);

  let logout = () => {
    setGlobal({ user: null });
    localStorage.removeItem("token");
    window.location.reload();
  };

  let [refresh, setRefresh] = useState(0);
  useEffect(() => {
    window.addEventListener("resize", setRefresh);
    return () => window.removeEventListener("resize", setRefresh);
  }, []);

  return (
    <>
      {!open ? (
        <Button
          large
          dark
          left={
            <img
              src={
                user?.avatar
                  ? window.host + "/files/" + user?.avatar
                  : "/user.png"
              }
              alt="avatar"
              style={{
                width: 40,
                height: 40,
                padding: 3,
                borderRadius: 8,
                marginLeft: 0,
              }}
            />
          }
          width={window.innerWidth < 1200 ? 44 : 200}
          noimgbrd
          text={
            window.innerWidth < 1200
              ? null
              : user?.name?.split(" ")?.reduce((f, n) => f || n, "")
          }
          height={44}
          radius={8}
          //onMouseEnter={() => setOpen(true)}
          onClick={() => setOpen(true)}
          right={
            window.innerWidth < 1200 ? null : (
              <div>
                {/* <MoreOutlined
        className="txt-white"
        style={{ margin: 0, padding: 2, width: "30px !important", fontSize: "1.5rem" }}
      /> */}
              </div>
            )
          }
        />
      ) : (
        <div style={{ width: window.innerWidth < 1200 ? 60 : 200, height: 40 }}>
          <div
            className="onMouseOut"
            onClick={() => {
              setOpen(false);
            }}
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              zIndex: 119,
              height: "100%",
              background: "#00000040",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              zIndex: 120,
              right: 0,
              marginRight: 20,
              marginTop: 0,
            }}
            //onMouseLeave={() => setOpen(false)}
          >
            <div
              style={{
                width: 200,
                background: "white",
                borderRadius: 8,
                paddingBottom: 8,
              }}
            >
              {/*<div
                style={{
                  margin: "8px",
                  padding: "8px",
                  color: "#5e6c84",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(9, 30, 66, 0.13)",
                }}
              >
                <div>Account</div>
                
              </div>*/}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <Button
                  large
                  white
                  left={
                    <img
                      src={
                        user?.avatar
                          ? window.host + "/files/" + user?.avatar
                          : "/user.png"
                      }
                      alt="avatar"
                      style={{
                        width: 30,
                        height: 30,
                        padding: 3,
                        marginLeft: 5,
                      }}
                    />
                  }
                  width={200}
                  noimgbrd
                  noimgbg
                  radius={8}
                  text={user?.name?.split(" ")?.reduce((f, n) => f || n, "")}
                  height={44}
                  onClick={() => setOpen(false)}
                  right={
                    <div>
                      {/* <MoreOutlined
          style={{ margin: 0, color: '#c1c1c1', padding: 2, width: "30px !important", fontSize: "1.5rem" }}
        /> */}
                    </div>
                  }
                />
                <CloseOutlined
                  onClick={() => setOpen(false)}
                  style={{ padding: "8px 12px 8px 8px", cursor: "pointer" }}
                />
              </div>

              <Button
                medium
                white
                left={
                  <img
                    src="/profile.svg"
                    style={{ width: 20, marginLeft: 10, height: 20 }}
                  />
                }
                width={200}
                noimgbg
                text={"Profile"}
                height={44}
                onClick={() => {
                  setOpen(false);
                  history.push("/profile");
                }}
              />
              {/* 
      <Button large white l left={<img src="/settings.svg" style={{width: 20, marginLeft: 10, height: 20}} />} 
        width={200}  noimgbg 
        text={'Settings'} height={44}  onClick={() => {
          setOpen(false)
          history.push("/settings") 
        }} /> */}

              {/* <div style={{borderTop: "2px solid #c1c1c1"}} /> */}

              <Button
                medium
                white
                left={
                  <img
                    src="/logout.svg"
                    style={{ width: 20, marginLeft: 10, height: 20 }}
                  />
                }
                width={200}
                noimgbg
                text={"Logout"}
                height={44}
                onClick={() => logout()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileMenu;
