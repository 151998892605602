import { Dropdown, Menu, Popover, List, Empty, message } from "antd";
import { useLocation, useHistory, Switch, Route } from "react-router-dom";
import React, { useGlobal, useEffect, setGlobal, useState } from "reactn";
import call from "../call";
import Card from './card'
import {
  UserOutlined,
  SettingFilled,
  LogoutOutlined,
  MoreOutlined,
  LeftOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Button from './button'
import Text from './text'
import FlexSearch from "flexsearch";
import {ReactComponent as OverviewIcon} from './icons/Overview.svg'
import {ReactComponent as GamesIcon} from './icons/Games.svg'
import {ReactComponent as DownloadsIcon} from './icons/Downloads.svg'
import {ReactComponent as InsightsIcon} from './icons/Insights.svg'
import {ReactComponent as FAQIcon} from './icons/FAQ.svg'
import {ReactComponent as AlertIcon} from './icons/alert.svg'
import Download from './download'


let SearchBox = (props) => {
  let history = useHistory()
  let [search, setSearch] = useState('')
  let [result, setResult] = useState([])
  let [images, setImages] = useState({});
  let [open, setOpen] = useState(props.open)
  let [version, setVersion] = useState(null)



  useEffect(() => {
    if(search.length > 0)
      call("search", {text: search}).then(setResult)
    else setResult([])
  }, [search])

  useEffect(() => {
    if(!open) props.onBlur && props.onBlur()
  }, [open])

  return <>
    {open &&
    <div style={{position: 'fixed', left: 0, top: 0, width: "100%", zIndex: 100,
      height: "100vh", background: '#00000040'}}
      onClick={() => setOpen(false)}></div>
    }
      
    <div style={{width: 300, height:44}}>

    <div style={{position: open ? 'absolute' : 'inherit',  zIndex: open ? 120 : 'inherit',
      padding:8, background: open ? 'white' : "#27313d",  borderRadius: 8, width: 300,
      height: open ? 'auto' : 44}}>
      <div style={{
        alignItems: 'center',
        width: 280,
        height:28,
        display: 'flex'
      }}>
            
      <SearchOutlined style={{ fontSize: "1.2rem", color: open ? 'black' : 'white'}} />
      <input value={search} onChange={e => setSearch(e.target.value)}
        onFocus={() => setOpen(true)} 
            placeholder="Search anything"
            style={{
              width: 280,
              fontSize: "1rem",
              paddingLeft: 10,
              background: 'none',
              border: 'none',
              color: open ? 'black' : 'white'
            }}
      />
    </div>
    {open && search.length > 0 && 
    <div style={{}}>
    {result.length > 0 &&
      <div>
        {result.map(x => <div key={x._id}>
          <Button width="100%" onClick={() => {
            if(x.type === 'game') window.location = `/edit/${x._id}`
            else {
              call("findPlayable", {_id: x._id}).then(result =>  {
                window.location = `/edit/${result.game}?playable=${result._id}`
              })
            }
          }}
          height={40} fontSize={14}
          noimgbrd small white text={x.name} />
        </div> )}
      </div> }
      {result.length == 0 && <Empty description="No Result" /> }
    </div>
    }
  </div>
  {version &&
    <Download version={version} open={true} onClose={() => setVersion(null)} />
  }
  </div>
</>
}

export default SearchBox
