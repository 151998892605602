import { message } from "antd";
import jwtdecode from "jwt-decode";
import { setGlobal } from "reactn";
import SocketIO from "socket.io-client";

window.token = localStorage.getItem("token");

let search = window.location.search;

let _token = search.replace("?token=", "");
if (search.indexOf("?token") >= 0 && _token && _token.length > 1) {
  window.token = _token;
  let user = jwtdecode(window.token);
  setGlobal({
    user: { ...user, isLoaded: false },
  });
}


const NODE_ENV = process.env.NODE_ENV;
let host = (window.host =
  NODE_ENV === "development"
    ? process.env.REACT_APP_API_HOST
    : "https://gearbox-old.playablefactory.com");
    host = "https://gearbox-old.playablefactory.com";
let socket = SocketIO(host);

setGlobal({
  user: window.token ? { token: window.token, isLoaded: false } : null,
});

// socket.on("mailConfirm", ({ token: t }) => {
//   window.token = t;
//   let remember = localStorage.getItem("remember");
//   let user = jwtdecode(window.token);
//   console.log({t})

//   if (remember === "remember") localStorage.setItem("token", window.token);
//   setGlobal({
//     user: { ...user, isLoaded: false },
//   });
// });

let call = async (action, params, http) => {
  if (action == "uploadFile") {
    var formData = new FormData();
    formData.append("token", window.token);
    formData.append("file", params);
    let result = await fetch(host + "/api/uploadFile", {
      method: "POST",
      body: formData,
    });

    result = await result.json();

    return result;
  }
  if (action == "uploadFileCDN") {
    var formData = new FormData();
    formData.append("token", window.token);
    formData.append("file", params);
    let result = await fetch(host + "/api/uploadFileCDN", {
      method: "POST",
      body: formData,
    });

    result = await result.json();

    return result;
  } else {
    return await new Promise((res, rej) => {
      console.log(action);
      socket.emit(
        "call",
        {
          action,
          params,
          token: window.token,
        },
        (result) => {
          if (result && result.error) {
            console.log(action, params, result);
            console.log(result.error);
            console.log(result);
            if (result.error && result?.error === "Session ended") {
              message.error("Session Ended");
              localStorage.removeItem("token");
              window.location.reload();
              return;
            }
            rej(new Error(result.error.msg));
          } else res(result);
        }
      );
    });
    // let {data: result} =  await Axios.post( "/api/call",{ action, params, token: window.token,})
    // if (result && result.error) {
    //   console.log(action, params, result);
    //   console.log(result.error);
    //   console.log(result)
    //   if(result.error && result?.error === 'Session ended')
    //   {
    //     message.error('Session Ended')
    //     localStorage.removeItem('token')
    //     window.location.reload()
    //     return;
    //   }
    //   throw new Error(result.error.msg);
    // } else return result;
  }
};

export default call;
