import React from 'react';

export function Clipboard({text, children, beforeCopy, afterCopy, onError}) {
  const elem = React.Children.only(children);
  const onClick = children.onClick
  const handleClick = (event) => {
    beforeCopy(text).then(res => navigator.clipboard.writeText(res ?? text)).then(res => afterCopy(res)).catch(onError)
    if (onClick && typeof onClick === "function") {
      onClick(event);
    }
  }
  return React.cloneElement(elem, { onClick: handleClick });
}
