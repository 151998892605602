import loadable from "@loadable/component";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import React, {
  useEffect,
  useGlobal,
} from "reactn";
import call from "./call";
import DashboardLayout from "./dashboard/layout";
import Login from "./login";

const LoginConfirmed = loadable(() => import(`./loginConfirmed`));
const LoginNotConfirmed = loadable(() => import(`./loginNotConfirmed`));
const ResetPassword = loadable(() => import(`./ResetPassword`));
const CompleteRegister = loadable(() => import(`./CompleteRegister`));

let Fallback = () => <div style={{ borderRadius: 16, height: "100vh" }} />;
// let EditLoading = () => <div style={{width: "100vw", height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//   <Loading />
//   </div>

// const Admin = loadable(() => import("./"));
const Customers = loadable(() => import(`./dashboard/customers`), {
  fallback: <Fallback />,
});
const Games = loadable(() => import(`./dashboard/games`), {
  fallback: <Fallback />,
});
const News = loadable(() => import(`./dashboard/news`), {
  fallback: <Fallback />,
});
const Tutorials = loadable(() => import(`./dashboard/tutorials`), {
  fallback: <Fallback />,
});
const GameEdit = loadable(() => import(`./dashboard/gameEdit`), {
  fallback: <Fallback />,
});
const Playables = loadable(() => import(`./dashboard/playables`), {
  fallback: <Fallback />,
});
const PlayableEdit = loadable(() => import(`./dashboard/playableEdit`), {
  fallback: <Fallback />,
});
// const Versions = loadable(() => import(`./dashboard/versions`), {fallback: <Fallback />} );
const Users = loadable(() => import(`./dashboard/users`), {
  fallback: <Fallback />,
});

const DiscontinueNotice = loadable(() => import("./dashboard/videos/discontinueNotice"), {
  fallback: <Fallback />,
});

const Videos = loadable(() => import("./dashboard/videos/games"), {
  fallback: <Fallback />,
});

const Versions = loadable(() => import("./dashboard/videos/versions"), {
  fallback: <Fallback />,
});
const UsersVideoManual = loadable(
  () => import("./dashboard/videos/usersVideoManual"),
  {
    fallback: <Fallback />,
  }
);
const Overview = loadable(() => import(`./dashboard`), {
  fallback: <Fallback />,
});
const MyGames = loadable(() => import("./dashboard/mygames"), {
  fallback: <Fallback />,
});
const DevMyGames = loadable(() => import("./dashboard/devmygames"), {
  fallback: <Fallback />,
});

const Insights = loadable(() => import("./dashboard/insights"), {
  fallback: <Fallback />,
});

const Downloads = loadable(() => import("./dashboard/downloads"), {
  fallback: <Fallback />,
});
const Records = loadable(() => import("./dashboard/records"), {
  fallback: <Fallback />,
});
const VersionEdit = loadable(() => import("./dashboard/playableVersionEdit"), {
  fallback: <Fallback />,
});
const RecordEdit = loadable(() => import("./dashboard/recordEditPage/index"), {
  fallback: <Fallback />,
});
const VideoEdit = loadable(() => import("./dashboard/videoEdit/provider"), {
  fallback: <Fallback />,
});
const VideoDemoRedirect = loadable(
  () => import("./dashboard/videoEdit/videoDemoRedirect"),
  {
    fallback: <Fallback />,
  }
);
const Profile = loadable(() => import("./dashboard/Profile"), {
  fallback: <Fallback />,
});
const Test = loadable(() => import("./dashboard/test"));
const Docs = loadable(() => import("./dashboard/docs"));

let App = () => {
  let [user, setUser] = useGlobal("user");

  useEffect(() => {
    if (user && user.isLoaded === false) {
      call("me").then((_user) =>
        setUser({ ...user, ..._user, isLoaded: true })
      );
    }
  }, [user, setUser]);

  // useEffect(() => {
  //   // Customers.preload()
  //   // Games.preload()
  //   // News.preload()
  //   // Tutorials.preload()
  //   // GameEdit.preload()
  //   // Playables.preload()
  //   // PlayableEdit.preload()
  //   // Versions.preload()
  //   // Versions.preload()
  //   // Overview.preload()
  //   // Users.preload()
  //   // MyGames.preload()
  //   // Downloads.preload()
  //   // VersionEdit.preload()
  //   // Downloads.preload()
  //   // Profile.preload()
  //   // ResetPassword.preload()
  //   // Settings.preload()
  //   // DevMyGames.preload()
  // }, [])

  if (user && !user.isLoaded) return <div />;
  else if (
    user === null &&
    !(
      window.location.pathname === "/loginConfirmed" ||
      window.location.pathname === "/loginNotConfirmed" ||
      window.location.pathname === "/resetPassword" ||
      window.location.pathname === "/test"
    )
  )
    return <Login />;
  else if (user && user.reset) return <ResetPassword />;
  return (
    <Router>
      <Switch>
        <Route path="/loginConfirmed" component={LoginConfirmed} />
        <Route path="/loginNotConfirmed" component={LoginNotConfirmed} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/completeRegister" component={CompleteRegister} />
        <Route path="/test" component={Test} />

        <Route path="/edit/:id">
          <VersionEdit />
        </Route>

        <Route path="/record/:id">
          <RecordEdit />
        </Route>
        <Route path={"/video/:id"}>
          <VideoEdit />
        </Route>

        <Route path={"/video-demo"}>
          <VideoDemoRedirect />
        </Route>

        <Route path="/">
          <DashboardLayout>
            <Switch>
              <Route path="/" exact component={Overview} />
              <Route path="/videos/games" exact component={DiscontinueNotice} />
              <Route path="/videos/versions" exact component={DiscontinueNotice} />
              <Route
                path="/videos/usersVideoManual"
                exact
                component={DiscontinueNotice}
              />
              <Route path="/downloads" exact component={Downloads} />
              <Route path="/records" exact component={Records} />
              <Route path="/profile" exact component={Profile} />
              {user &&
                [
                  "admin",
                  "pm",
                  "tester",
                  "developer",
                  "viewer",
                  "artist",
                  "customer",
                ].includes(user.role) && (
                  <Route path="/insights" exact component={Insights} />
                )}

              {/* {(user && ( user.role === 'admin' || user.role === 'pm' ||
                user.role === 'customer' || user.role === 'developer' || user.role == 'customer')) &&
                <Route path="/insights" exact component={Insights} />
              } */}
              {user &&
                ["admin", "pm", "developer", "tester", "artist"].includes(
                  user.role
                ) && (
                  <Route path="/docs">
                    <Docs />
                  </Route>
                )}

              {user && (user.role === "admin" || user.role === "pm") && (
                <>
                  <Route path="/customers" exact component={Customers} />
                  <Route path="/users" exact component={Users} />
                  <Route path="/games" exact component={Games} />
                  <Route path="/news" exact component={News} />
                  <Route path="/tutorials" exact component={Tutorials} />
                  <Route path="/games/:id" exact component={GameEdit} />
                  <Route path="/playables" exact component={Playables} />

                  {/* <Route path="/versions" exact component={Versions} /> */}
                  <Route path="/playables/:id" component={PlayableEdit} />
                </>
              )}
              {user?.role === "developer" && (
                <>
                  <Route
                    path="/mygames"
                    exact
                    component={user.role === "developer" ? DevMyGames : MyGames}
                  />
                  <Route path="/playables" exact component={Playables} />
                  <Route path="/playables/:id" component={PlayableEdit} />
                </>
              )}
              {user?.role === "customer" && (
                <Route
                  path="/mygames"
                  exact
                  component={user.role === "developer" ? DevMyGames : MyGames}
                />
              )}
              {user?.role === "viewer" && (
                <Route path="/playables" exact component={Playables} />
              )}
              {user?.role === "tester" && (
                <Route path="/playables" exact component={Playables} />
              )}
              {user?.role === "artist" && (
                <Route path="/playables" exact component={Playables} />
              )}
            </Switch>
          </DashboardLayout>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
