import { useLocation } from "react-router-dom";
import React, {
  setGlobal,
  useEffect,
  useGlobal,
  useState,
} from "reactn";
import call from "../call";
import Header from './Header';
import SideMenu from './SideMenu';

let AppLayout = (props) => {
  let location = useLocation();

  let path = location.pathname;

  let [user] = useGlobal("user");

  useEffect(() => {
    if (user && user.token && !user.isLoaded) {
      call("me", {}).then((_user) =>
        setGlobal({ user: { ...user, ..._user, isLoaded: true } })
      );
    }
  }, [user]);

  let [refresh, setRefresh] = useState(0)
  useEffect(() => {
    window.addEventListener("resize", setRefresh)
    return () => window.removeEventListener("resize", setRefresh)
  }, [])


  return (
    <div style={{padding: 0, minHeight: '80vh', display: 'flex', flexDirection: 'column', 
      maxWidth: 2400, margin: "0 auto", position: 'relative', minWidth: 800 }}>
      <Header/>
      
      <div style={{ position: 'relative'}}>
        <div style={{float: 'left', width: 200}}>
        {props.SideMenu && <props.SideMenu />}  
        {!props.SideMenu && <SideMenu /> }
        </div>
                
        <div style={{ width : 'calc(100% - 200px - 16px)',
          minHeight: 500,
          flex: 1, backgroundColor: 'rgb(248, 249, 253)',
          position: 'relative', marginLeft: 200,
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: path == '/' ? 'auto' :  'calc(100vh - 60px - 16px)' ,
          marginBottom: path == '/' ? 16 : 0,
          borderRadius: 16,}}>
            {props.children}
        </div>


      </div>
    </div>
  );
};

export default AppLayout;
