import React, { useState } from "react";
let Button = (props) => {
  let [hover, setHover] = useState(false);
  const [coords, setCoords] = React.useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState(false);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 1200);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  let click = (e) => {
    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    setCoords({ x, y });
    setTimeout(() => {
      props.onClick && props.onClick();
    }, 150);
  };

  return (
    <button
      onClick={click}
      onMouseEnter={props.onMouseEnter}
      onAuxClick={props.onAuxClick || click}
      className={
        isRippling
          ? `ripple-button rip ${props.className ? props.className : ""}`
          : `ripple-button ${props.className ? props.className : ""}`
      }
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      id={props.id}
      style={{
        cursor: "pointer",
        boxShadow:
          props.shadow == "small"
            ? "0px 0px 20px -10px rgba(0,0,0,0.75)"
            : props.shadow
            ? "0px 0px 40px -18px rgba(0,0,0,0.75)"
            : "inherit",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: props.text ? "flex-start" : "center",
        height: props.height || (props.large ? 44 : props.medium ? 36 : 32),
        fontSize: props.fontSize || (props.large ? 16 : props.medium ? 14 : 12),
        width: props.width || 220,
        backgroundColor: props.nobg
          ? "inherit"
          : props.hover && hover
          ? props.hover === "orange2"
            ? "rgb(245 ,119, 42)"
            : props.hover === "orange"
            ? "#f78743"
            : props.hover === "grey"
            ? "#f5f8fb"
            : props.hover === "grey2"
            ? "#e5e9ec"
            : props.hover === "bold"
            ? "#b1b1b1"
            : props.hover === "boldgrey"
            ? "#cad4dc"
            : props.hover === "white"
            ? "white"
            : "#f78743"
          : props.pink
          ? "#fef3ed"
          : props.yellow
          ? hover
            ? "#EEB44F"
            : "#EEB44F"
          : props.white
          ? hover
            ? "#f2f2f2"
            : "white"
          : props.grey
          ? hover
            ? "#e5e9ec"
            : "#f5f9fc"
          : props.dark
          ? hover
            ? "#4c5b6e"
            : "#3b4654"
          : props.orange
          ? "#f78743"
          : hover
          ? "#c6cce5"
          : "#e6ecf5",
        borderRadius:
          props.radius != null
            ? props.radius
            : props.oval
            ? props.large
              ? 40
              : props.medium
              ? 32
              : 25
            : props.large
            ? 16
            : 8,
        color:
          hover &&
          (props.hover === "white" ||
            props.hover === "grey" ||
            props.hover === "boldgrey")
            ? "black"
            : (hover && props.hover === "orange") ||
              (hover && props.hover === "bold") ||
              props.yellow ||
              props.dark ||
              props.orange
            ? "white"
            : "black",
        position: "relative",
      }}
    >
      {props.left && typeof props.left === "string" && (
        <img
          alt=""
          src={hover && props.leftHover ? props.leftHover : props.left}
          style={{
            borderRadius: "34%",
            padding: props.noimgbrd
              ? 0
              : props.large
              ? 8
              : props.medium
              ? 6
              : 4,
            height: props.large ? 32 : props.medium ? 28 : 20,
            width: props.large ? 32 : props.medium ? 28 : 20,
            marginLeft: props.leftMargin || 0,
            left: 10,
            backgroundColor:
              props.nobg || props.noimgbg
                ? "inherit"
                : props.yellow
                ? hover
                  ? props.hover == "white"
                    ? "#EEB44F"
                    : "white"
                  : "white"
                : props.imggrey
                ? "#f7874430"
                : props.white
                ? hover
                  ? "white"
                  : "#fb8c47"
                : props.iconWhite
                ? "#f7874430"
                : props.dark
                ? "#f7874430"
                : hover
                ? "#f5c063"
                : "#f5c063",
          }}
        />
      )}
      {props.left &&
        typeof props.left !== "string" &&
        (hover && props.leftHover ? props.leftHover : props.left)}
      {props.text && (
        <span
          style={{
            marginLeft: props.textCenter ? 0 : 12,
            marginRight: 10,
            flex: 1,
            textAlign: props.textCenter ? "center" : "left",
            fontWeight: props.bold ? "bold" : "inherit",
            color: props.textGrey ? "#a3a3a3" : "inherit",
          }}
        >
          {props.text}
        </span>
      )}

      {props.right && (
        <span>
          {hover && props.rightHover ? props.rightHover : props.right}{" "}
        </span>
      )}

      {/* {isRippling ? (
        <span
          className="ripple"
          style={{
            left: coords.x + 10,
            top: coords.y
          }}
        />
      ) : (
        null
      )} */}
    </button>
  );
};

export default Button;
