import {
  AndroidFilled,
  BookFilled,
  FolderFilled,
  NotificationFilled as NewsFilled,
  PlayCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";
import {
  useEffect,
  useState,
} from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import React, { useGlobal } from "reactn";
import call from "../call";
import Button from "./button";
import { ReactComponent as DownloadsIcon } from "./icons/Downloads.svg";
import { ReactComponent as GamesIcon } from "./icons/Games.svg";
import { ReactComponent as InsightsIcon } from "./icons/Insights.svg";
import { ReactComponent as OverviewIcon } from "./icons/Overview.svg";
import Text from "./text";

const { Panel } = Collapse;
let AppLayout = (props) => {
  let history = useHistory();
  let location = useLocation();
  let path = location.pathname;

  let [user] = useGlobal("user");
  let [customer, setCustomer] = useState();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.role === "customer") call("findCustomer", {}).then(setCustomer);
  }, [user]);

  return (
    <div style={{ width: 200, marginLeft: 0, marginTop: 16 }}>
      <div style={{ marginLeft: 16 }}>
        {user?.role == "developer" && (
          <Button
            left="/plus.png"
            leftHover="/pluswhite.png"
            medium
            height={50}
            width={200 - 32}
            text="Upload Base Playable"
            radius={16}
            fontSize={15}
            yellow
            hover="white"
            onClick={() => history.push("/playables/new")}
            onAuxClick={() =>
              window.open(
                "/playables/new",
                "p" + Math.floor(Math.random() * 100 + 1)
              )
            }
          />
        )}

        {user?.role == "customer" && (
          <Button
            left="/plus.png"
            leftHover="/pluswhite.png"
            medium
            height={50}
            width={200 - 32}
            text="Create Playable"
            radius={16}
            fontSize={15}
            yellow
            hover="white"
            onAuxClick={() =>
              window.open("/mygames", "p" + Math.floor(Math.random() * 100 + 1))
            }
            onClick={() => history.push("/mygames")}
          />
        )}
      </div>

      <div
        style={{
          marginTop: 20,
          height: "calc(100vh - 120px)",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {[
          [
            "/",
            "Overview",
            OverviewIcon,
            "admin,pm,developer,viewer,tester,demo,user,customer,artist,customerAdmin,customerOwner",
          ],
          ["/mygames", "Games", GamesIcon, "customer,"],
          ["/mygames", "My Games", GamesIcon, "developer,"],
          [
            "/playables",
            "Playables",
            (props) => <AndroidFilled {...props} />,
            "admin,pm,developer,tester,viewer,artist",
          ],
          [
            "/downloads",
            "Downloads",
            DownloadsIcon,
            "admin,pm,developer,viewer,tester,demo,user,customer,artist,customerAdmin,customerOwner",
          ],
          [
            "/records",
            "Records",
            (props) => <PlayCircleOutlined {...props} />,
            "admin,pm",
          ],
          [
            "/insights",
            "Insights",
            InsightsIcon,
            "admin,pm,developer,viewer,tester,demo,user,artist,customer,customerAdmin,customerOwner",
          ],
          [
            "/customers",
            "Customers",
            (props) => <UserOutlined {...props} />,
            "admin,pm,",
          ],
          [
            "/users",
            "Users",
            (props) => <UserOutlined {...props} />,
            "admin,pm,",
          ],
          ["/games", "Games", GamesIcon, "admin,pm,"],

          ["/news", "News", (props) => <NewsFilled {...props} />, "admin,pm"],
          [
            "/tutorials",
            "Tutorials",
            (props) => <BookFilled {...props} />,
            "admin,pm",
          ],
          [
            "/docs",
            "Documents",
            (props) => <FolderFilled {...props} />,
            "admin,pm,developer,viewer,tester,artist",
          ],
        ]
          .filter(
            ([url, name, icon, roles]) => user && roles.indexOf(user.role) >= 0 && !(user.customer=="sv39m1kZRaIdB4iwNlVbAr4YR" && name=="Insights")
          )
          .map(([url, name, Icon]) => (
            <div
              key={url}
              style={{ marginLeft: 16, marginRight: -1 }}
              onClick={() => history.push(url)}
              onAuxClick={(x) =>
                window.open(url, "p" + Math.floor(Math.random() * 100 + 1))
              }
            >
              <div
                style={{ height: 44, cursor: "pointer", position: "relative" }}
              >
                {
                  url == path && (
                    <div
                      style={{
                        background: "rgb(248, 249, 253)",
                        width: 184,
                        height: 44,
                        position: "absolute",
                        zIndex: -1,
                        borderRadius: 8,
                      }}
                    >
                      <div
                        style={{
                          background: "rgb(248, 249, 253)",
                          width: 8,
                          height: 44 + 16,
                          float: "right",
                          marginTop: -8,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            marginTop: -8,
                            marginLeft: -8,
                            background: "#303C4A",
                            width: 16,
                            height: 16,
                            borderRadius: 8,
                          }}
                        />

                        <div
                          style={{
                            marginTop: 44,
                            marginLeft: -8,
                            background: "#303C4A",
                            width: 16,
                            height: 16,
                            borderRadius: 8,
                          }}
                        />
                      </div>
                    </div>
                  )
                  //  <img src={Active} style={{
                  //   cursor: 'pointer',  position: "absolute", width: '100%', zIndex: -1,
                  //   height: 96 , backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                  //   marginLeft: 3, marginTop: -17}}>
                  // </img>
                }
                <div
                  className={path === url ? "" : "sidebtn"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 20,
                    height: 44,
                    marginTop: 10,
                    borderBottomLeftRadius: 20,
                    borderTopLeftRadius: 20,
                    color: path === url ? "black" : "white",
                    fontSize: 15,
                  }}
                >
                  <Icon
                    style={{
                      width: 30,
                      fill: path === url ? "#efb64e" : "white",
                      marginRight: 10,
                      borderRadius: 10,
                      background: path === url ? "white" : "none",
                      padding: 6,
                    }}
                  />
                  <span style={{ fontFamily: "productBold" }}>{name}</span>
                </div>
              </div>
              {/* <Button yellow large width={300} nobg={path !== url}
            onClick={() => history.push(url)}
            left={path === url ? "/dotactive.png" : "/dot.png"}
            text={name}
          >


            {/* <div className="round-c">
              <span className="item bg"></span>
              <span className="item round top"></span>
              <span className="item round bottom"></span>
            </div> *
          </Button> */}
            </div>
          ))}
      </div>

      <div
        style={{
          position: "fixed",
          textAlign: "center",
          width: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
        }}
      >
        <Text grey>Powered by Playable Factory</Text>
      </div>
    </div>
  );
};

export default AppLayout;
