import { LockFilled, MailOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, message } from "antd";
import jwtdecode from "jwt-decode";
import React, { setGlobal, useEffect, useGlobal, useState } from "reactn";
import call from "./call";

let Login = () => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("")
  let [sent, setSent] = useState(false);
  let [waiting, setWaiting] = useState(false);
  let [remember, setRemember] = useState(true);
  let [showReset, setShowReset] = useState(false)
  let [token, setToken] = useGlobal("token")
  let [slide, setSlide] = useState(0)

  let reset = async () => {
    try {
      if(waiting) return;
      localStorage.setItem("remember", remember ? "remember" : null);
      if (email.length === 0 || sent || waiting) return;
      setWaiting(true);
      await call("sendResetPasswordMail", { email });
      setSent(true);
      message.success("Your reset link sent to " + email )
    } catch (e) {
      message.error(e.message, 5);
    }
    setWaiting(false);
  };

  useEffect(() => {
    let t = setTimeout(() => setSlide((slide + 1) % 3 ) , 4000)

    return () => clearTimeout(t)
  }, [slide])


  let login = async () => {
    try {
      if(waiting) return;
      localStorage.setItem("remember", remember ? "remember" : null);
      if (email.length === 0 || sent || waiting) return;
      setWaiting(true);
      let token = await call("login", { email, password });
      window.token = token
      
      let user = jwtdecode(token)
      
      if(remember)
      localStorage.setItem('token', token)
      setGlobal({
        user: {...user, isLoaded: false}
      })
      message.success("Success" )
    } catch (e) {
      message.error(e.message, 5);
    }
    setWaiting(false);
  };

  return (
    <div className="layout bg-white" style={{padding:0}}>
      <div style={{ display: "flex" }}>
        <div style={{flex: 1, padding:20}}>
          <div >
            <img alt="" src="/logodark.png" style={{width: 180, padding: 20}} />
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <div className="txt-dark">
              {sent && (
                <div
                  style={{
                    width: 600,
                    display: "flex",
                    flexDirection: "column",
                    height: 280,
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/logodark.png"
                    alt="logo"
                    style={{ height: 30 }}
                  />
                  <h2
                    className="txt-yellow"
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    Your reset link sent to {email}
                  </h2>
                  <p style={{ textAlign: "center", fontSize: "1.125rem" }}>
                    You can close this window
                  </p>

                  <Button onClick={() => setSent(false)}>Go back</Button>
                </div>
              )}
              {!sent && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",

                    padding: 20,
                    width: 380,
                    paddingLeft: 40,
                    paddingRight: 40,
                  }}
                >
                  <p
                    level={2}
                    className="txt-dark title"
                    style={{ fontSize: "1.625rem", marginBottom: 24 }}
                  >
                    {showReset ? "Reset your password" : "Login"}
                  </p>
                  <div style={{ width: "100%" }}>
                    <Input
                      className="input"
                      prefix={<MailOutlined />}
                      size="large"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.trim())}
                      disabled={sent}
                      onKeyDown={(e) => (e.key === 'Enter' || e.keyCode === 13) && ( showReset ? reset() : login()) }
                      placeholder="Email"
                      style={{ width: "100%" }}
                    />
                  </div>
                  {!showReset && <>
                  <div style={{ width: "100%", marginTop:8 }}>
                    <Input.Password
                      className="input"
                      prefix={<LockFilled />}
                      size="large"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={(e) => (e.key === 'Enter' || e.keyCode === 13) && login() }
                      disabled={sent}
                      placeholder="password"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <button
                    className="btn btn-small btn-yellow txt-white full"
                    style={{ marginTop: 20 }}
                    onClick={login}
                    type="link"
                  >
                    <RightOutlined className="icon white txt-yellow right bg-white" style={{background: 'white'}}/>
                    <span className="text">Login</span>
                  </button> 

                  <div style={{display: 'flex', justifyContent: 'space-between', width: "100%", marginTop:16}}>
                  <Checkbox
                    
                    className="txt-grey2"
                    checked={remember}
                    onChange={(e) => setRemember(e.target.checked)}
                  >
                    Remember
                  </Checkbox>

                  <Button type="link" onClick={() => setShowReset(true)} >Reset Password</Button>
                  </div>
                  </>}

                  {showReset &&  <> <button
                    className="btn btn-small btn-yellow txt-white full"
                    style={{ marginTop: 20 }}
                    onClick={reset}
                    type="link"
                  >
                    <RightOutlined className="icon white txt-yellow right bg-white" style={{background: 'white'}}/>
                    <span className="text">Reset</span>
                  </button>
                  
                  <a className="btn btn-small btn-grey2 txt-orange full" style={{marginTop: 10, width: 80, color: '#5a5a5a'}}
                    onClick={() => setShowReset(false)}>
                    <span className="text">{"< back"}</span>
                  </a>
                  </>}
                
                
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="bg-blue"
          style={{ flex: 1, height: "100vh", background: "#1e7cf5", display: "flex",
            justifyContent: "center", alignItems: "center", flexDirection: "column" }}
        >
          {slide === 0 &&
          <div style={{display: "flex", height: 400, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div className="txt-yellow title">Create <span className="txt-white">Your Playable Ad</span></div>
            <img src="/lplayable.png" alt="" style={{width: 400, height:260, marginTop: 20}} />
            <div className="txt-white" style={{width: 530, marginTop: 20, textAlign:"center", fontSize: 16}}>
            GearBox is the place where you can create and iterate your own playable ads. Simple, fast and easy to use. No need for any coding knowledge.
            </div>
          </div>
          }

          {slide === 1 &&
          <div style={{display: "flex", height: 400, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div className="txt-yellow title">Download <span className="txt-white">Your Playable Ad</span></div>
            <img src="/ldownload.png" alt="" style={{width: 400, height:260, marginTop: 20}} />
            <div className="txt-white" style={{width: 460, marginTop: 20, textAlign:"center", fontSize: 16}}>
            See your all playable ad versions in one place. Ready to use on all well-known ad networks.
            </div>
          </div>
          }


          {slide === 2 &&
          <div style={{display: "flex", height: 400, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div className="txt-yellow title">Track <span className="txt-white">Your Playable Ad</span></div>
            <img src="/linsights.png" alt="" style={{width: 400, height:260, marginTop: 20}} />
            <div className="txt-white" style={{width: 460, marginTop: 20, textAlign:"center", fontSize: 16}}>
              Track the user and see what happens inside the playable. Get insights about your potential users.
            </div>
          </div>
          }

        <div style={{display: 'flex', height: 100,justifyContent: 'center', alignItems: 'center'}}>
          {[0,1,2].map((n,i) => <div key={n} onClick={() => setSlide(i)}
            style={{
            width: i == slide ? 100 : 12, height:12, borderRadius: 6,
            background: i == slide ? '#efb64e' : '#e3e4e8',
            margin: 8, cursor: 'pointer'
          }}  ></div> )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
