import { Dropdown, Menu, Popover, List, Empty, message } from "antd";
import { useLocation, useHistory, Switch, Route } from "react-router-dom";
import React, { useGlobal, useEffect, setGlobal, useState } from "reactn";
import call from "../call";
import Card from './card'
import {
  LeftOutlined,
} from "@ant-design/icons";
import Button from './button'
import Text from './text'
import SearchBox from './SearchBox'
import Profile from './ProfileMenu'

let AppLayout = (props) => {
  let history = useHistory();
  let location = useLocation();
  let [games, setGames] = useGlobal('games')
  let [versions] = useGlobal('versions')
  let [showSearch, setShowSearch] = useState(false)

  let path = location.pathname;

  let [user] = useGlobal("user");

  let capitalizeFLetter = (str) => { 
    if(str == null) return;
    return str.toLowerCase().split(" ").map(x => {
      if(x.length > 0)
        return x[0].toUpperCase() + x.slice(1, x.length)
      return x;
    }).join(" ")
  }

  return (
    <div style={{ display: "flex", width: "100%", alignItems: 'center', 
    paddingLeft: 10, paddingRight: 30 , height: 60}}>
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.SideMenu && (
        <LeftOutlined className="txt-white" style={{ fontSize: "2.5rem" }} onClick={() => history.goBack()} />
      )}
      <img alt="" src="/logo.png" style={{ marginLeft: 4, width: 175 , marginTop: 14, cursor: 'pointer'}}
        onClick={() => history.push('/') } />
    </div>
   

    <div style={{ display: "flex", flex:1, justifyContent:'space-between', marginLeft: 20 }}>
      <Switch>
        <Route path="/" exact>
          {window.innerWidth >= 800 &&
          <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20}}>
            <span style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'productBold'}}>
              <Text yellow>Welcome back </Text>
              <Text white>
                {capitalizeFLetter((user?.name || user?.username || "").split(' ').reduce((f, n) => f || n , null ) )}!
              </Text>
            </span>
            <Text grey bold fontSize={14}>
              Have a nice day at work &#128522;
            </Text>
          </div> }
          {window.innerWidth < 800 && <div />} 
        </Route>
        <Route path="/">
          <SearchBox />
          
          {props.TopMenu && <props.TopMenu />}
         
        </Route>
      </Switch>

      <div style={{display: 'flex', alignItems: 'center'}}>
        {/* {!showSearch &&
        <button className="cleanbtn" style={{width: 70, height: 70}} >
          <AlertIcon style={{width: 36, height: 36, fill: 'white'}} />
        </button>
        } */}

        <Route path='/' exact> <>
          {showSearch && 
          <div >
          <SearchBox open={true} onBlur={() => setShowSearch(false)} />
          </div>
          }
          {!showSearch &&
          <Button large dark onClick={() => setShowSearch(true) } 
            left={<img src="/search.png" alt="search" style={{width: 30, height: 30, padding: 8}} />} 
            dark height={44} width={44} noimgbg radius={8} />
          }
          </>
        </Route>
        <div style={{width: 20}}></div>
        <Profile />
        
      </div>
    </div>
  </div>

 
  );
};

export default AppLayout;
