
import React from 'react'


let Card = (props) => {
  let r = props.radius != null ? props.radius : 16

  return <div className={ props.className || "noscroll" }
    style={{
      overflow: props.scroll ? "scroll" : 'hidden',
      padding: props.padding >= 0 ? props.padding : 20,
      borderTopLeftRadius: props.noleft ? 0 : r,
      borderBottomLeftRadius: props.noleft ? 0 : r,
      borderTopRightRadius: props.noright ? 0 : r,
      borderBottomRightRadius: props.noright ? 0 : r,
      width: props.width || '100%',
      height: props.height || 'inherit',
      maxHeight : props.maxHeight || 'inherit',
      minHeight: props.minheight || 'inherit',
      background: props.nobg ? 'nope' : (
        props.grey3 ? '#f8f9fd' : 
        (props.orange ? 'url("bg.png")' : 
        // (props.orange ? '#eeb44f' : 
        (props.grey2 ? '#e5eef5' : 
        (props.grey ? '#f5f8fb' : 'white')))),
      color : 'black'
    }} >
    {props.children}
  </div>
}


export default Card