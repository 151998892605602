import PropTypes from 'prop-types';
import React from 'react';

Text.propTypes = {
  fontSize: PropTypes.number,
  className: PropTypes.string,
  bold: PropTypes.bool,
  yellow: PropTypes.bool,
  grey: PropTypes.bool,
  grey2: PropTypes.bool,
  blue: PropTypes.bool,
  orange: PropTypes.bool,
};

function Text(props) {

  return <span
    className={props.className}
    style={{
      ...(props.style || {}),
      fontSize: props.fontSize || 'inherit',
      fontFamily: props.bold ? 'productBold' : 'productRegular',
      color : props.yellow ? '#EEB44F' :
        (props.white ? 'white' :
        (props.grey ? '#a1a1a1' :
        (props.grey2 ? '#54555a' :
        (props.blue ? '#2B5ECA' :
        (props.orange ? '#f78743' : 'black' )))))
    }} >
    {props.children}
  </span>
}


export default React.memo(Text)
